import React, { useEffect } from "react"
import "./style.scss"

const WistiaVideoInline = ({ id }) => {
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")

    script1.src = "https://fast.wistia.com/embed/medias/" + id + ".jsonp"
    script1.async = true

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
    script2.async = true

    document.body.appendChild(script1)
    document.body.appendChild(script2)
  })

  return (
    <div className="wistia-video-inline">
      <div className="wistia_responsive_padding">
        <div className="wistia_responsive_wrapper">
          <div className={`wistia_embed wistia_async_${id} videoFoam=true`}>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WistiaVideoInline
