import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import FreeTrialSignup from '../../components/FreeTrialSignup';
import Loader from '../../components/Loader';
import WistiaPopoverVideo from '../../components/WistiaPopoverVideo';
import WistiaVideoInline from '../../components/WistiaVideoInline';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Meet GlassHive | GlassHive"
                description="Check out the video to learn more about all the features GlassHive has to offer to make sales and marketing simple & fun."
            />
            <div className="overview-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">Meet GlassHive</h1>
                            <p className="hero-subtext white-text">
                                Make your sales and marketing simple and fun
                            </p>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-600">
                            <WistiaVideoInline id={'ugvjj33wg5'} />
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../images/overview/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="centered-text max-width-900">
                            <h4>All-in-one sales and marketing platform</h4>
                            <p>
                                Designed especially for the IT industry,
                                GlassHive offers a ton of features to make sales
                                and marketing easier for your team.
                            </p>
                            <p>
                                Whether you’re a small or enterprise business,
                                GlassHive has all the features you need to grow
                                your company and close more sales.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="integration-row">
                        <Col className="two-fifth">
                            <img
                                src={require('../../images/overview/logo-ball.png')}
                                alt="circle containing integration logos"
                            />
                        </Col>
                        <Col className="two-fifth">
                            <h4>Integrated with the tools you use most</h4>
                            <p>
                                GlassHive gives you the capability to connect to
                                other platforms that are used for sales and
                                marketing. Some of the tools that we integrate
                                with are:
                            </p>
                            <ul>
                                <li>ConnectWise</li>
                                <li>HubSpot</li>
                                <li>Salesforce</li>
                                <li>Google Analytics</li>
                                <li>Autotask</li>
                                <li>Office 365</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col className="two-fifth">
                            <h4>Rich with sales and marketing features</h4>
                            <p>
                                GlassHive comes equipped with all the tools your
                                sales and marketing team need to achieve
                                success. Here are some of the features that are
                                built in:
                            </p>
                            <ul>
                                <li>Email Campaigns</li>
                                <li>Automated Activity Tracking</li>
                                <li>Reporting</li>
                                <li>Journeys</li>
                                <li>Marketing Content</li>
                                <li>Collateral Builder</li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
